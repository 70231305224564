import React, { useState, useContext } from 'react';

import { Card, CardContent, CardActions, Button, Typography } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom'

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import ClientContext from '../../../contexts/ClientContext';

import SessionsList from '../../../components/Sessions/List';

import Screen from '../../UI/Screen';
import ConfirmDialog from '../../UI/ConfirmDialog';

import AnimalInfos from './Infos';

import { updateAnimalPath, createSessionPath } from '../../../services/routesPaths';


export default function ViewAnimal() {
    const {
        client,
        getCurrentAnimal,
        deleteAnimal,
        header
    } = useContext(ClientContext);
    
    const animal = getCurrentAnimal();

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const handleDeleteAnimal = () => {
        setConfirmDialogOpen(true);
    };

    const handleConfirmDeleteAnimal = () => {
        deleteAnimal(animal);
        //setConfirmDialogOpen(false);
    };

    const handleCancelDeleteAnimal = () => {
        setConfirmDialogOpen(false);
    };
    
    return (
        <Screen header={header}>
            <Card elevation={3}>
                <CardContent className="detailsCardContent">
                    <Typography className="detailsCardTitle" color="primary" gutterBottom>
                        Informations
                    </Typography>
                    <AnimalInfos animal={animal} />
                </CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        onClick={handleDeleteAnimal}
                        color="secondary"
                    >
                        Supprimer
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        component={RouterLink}
                        to={updateAnimalPath(client._id, animal._id)}
                    >
                        Modifier
                    </Button>
                </CardActions>
            </Card>

            <Card elevation={2}>
                <CardContent>
                    <Typography className="detailsCardTitle" color="primary" gutterBottom>
                        Séances
                    </Typography>

                    {!animal.deceased &&
                        <CardActions className="cardActionCenter">
                            <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                color="primary"
                                component={RouterLink}
                                to={createSessionPath(client._id, animal._id)}
                            >
                                Ajouter
                            </Button>
                        </CardActions>
                    }
                    
                    { animal.sessions && <SessionsList sessions={animal.sessions} /> }

                    {(!animal.deceased && animal.sessions.length > 8) &&
                        <CardActions className="cardActionCenter">
                            <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                color="primary"
                                component={RouterLink}
                                to={createSessionPath(client._id, animal._id)}
                                sx={{ mt: 2}}
                            >
                                Ajouter
                            </Button>
                        </CardActions>
                    }
                </CardContent>
            </Card>

            <ConfirmDialog
                open={confirmDialogOpen}
                title="Suppression d'une fiche animal"
                onCancel={handleCancelDeleteAnimal}
                onConfirm={handleConfirmDeleteAnimal}
            >
                Vous êtes sur le point de supprimer définitivement une fiche animal, tous ses comptes-rendus de séances seront aussi définitivement perdus. Souhaitez-vous continuer ?
            </ConfirmDialog>
        </Screen>
    );
}