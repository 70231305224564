import React, { useContext, useEffect, useState } from 'react';

import { Box, Grid, Card, CardContent, CardActions, Typography, Divider, Button } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom'

import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import { TextField, Select } from '../../UI/Forms';
import { TextField as MuiTextField } from '@mui/material';

import * as yup from 'yup';
import { isValid, format } from "date-fns";

import { generateSessionNumber } from '../../../models/sessions';

import DrawnReport from '../DrawnReport';
import { SESSION_SKELETON_IMAGES_INFOS } from '../../../config/sessions';
import ClientContext from '../../../contexts/ClientContext';

import { sessions as sessionsConfig } from '../../../config/customer.js';


const validationSchemaDef = {
    date: yup.string(),
    number: yup.string(),
    treatment: yup.string(),
    reason: yup.string(),
    shapes: yup.string()
};

let practitionersOptions = [], practitionersOptionsValues = [''];

if(sessionsConfig?.practitioners?.active) {
    for (const practitioner of sessionsConfig.practitioners.list) {
        practitionersOptions.push({ value: practitioner, name: practitioner });
        practitionersOptionsValues.push(practitioner);
    }
}

const validationSchema = yup.object().shape(validationSchemaDef);

export default function Form({values, onSubmit, cancelButtonProps, animal}) {
    const {
        client
    } = useContext(ClientContext);

    const methods = useForm({
        mode: "onBlur",
        resolver: yupResolver(validationSchema)
    });
    const { register, handleSubmit, formState: { errors }, setValue } = methods;
    
    const [submitStatus, setSubmitStatus] = useState({
        processing: false,
        data: null
    });

    useEffect(() => {
        if( submitStatus.processing === false
        &&  submitStatus.data != null
        &&  (!Object.keys(submitStatus.data.shapes.left).length || submitStatus.data.images.left != null)
        &&  (!Object.keys(submitStatus.data.shapes.right).length || submitStatus.data.images.right != null)) {
            onSubmit(submitStatus.data);
        }
    }, [submitStatus, onSubmit]);
    
    const today = new Date();

    let isUpdate = true;

    let reportNumberUniqId = null;
    let reportNumberClientName = null;
    let reportNumberAnimalName = null;
    let reportNumberReason = null;
    let reportDate = null

    //  in creation mode: setting up report number fields
    if(values == null || !values.hasOwnProperty("_id")) {
        isUpdate = false;
        
        if(sessionsConfig.numberFormat.search('{uniqId}') !== -1) {
            // base = timestamp animal creation - current timestamp (seconds)
            reportNumberUniqId = Math.abs(parseInt(Math.round(animal._id.split('/')[1]/1000)-Date.now()/1000)).toString();
    
            const reportNumberUniqIdLength = reportNumberUniqId.length;
    
            if(reportNumberUniqIdLength < 8) {
                const min = Math.pow(10, (8-reportNumberUniqId.length));
                const max = Math.floor(9.999999999*min);
                const random = (Math.floor(Math.random() * (max - min + 1)) + min).toString();
    
                reportNumberUniqId = random + reportNumberUniqId;
            } else if(reportNumberUniqIdLength > 8) {
                reportNumberUniqId = reportNumberUniqId.substring(reportNumberUniqIdLength-8);
            }
        }

        if(sessionsConfig.numberFormat.search('{date}') !== -1) {
            reportDate = today;
        }

        if(sessionsConfig.numberFormat.search('{clientName}') !== -1) {
            reportNumberClientName = client.lastname;
        }

        if(sessionsConfig.numberFormat.search('{animalName}') !== -1) {
            reportNumberAnimalName = animal.name;
        }

        if(sessionsConfig.numberFormat.search('{reason}') !== -1) {
            reportNumberReason = "";
        }

        values = {
            date: format(today, 'yyyy-MM-dd'),
            number: generateSessionNumber(reportNumberClientName, reportNumberAnimalName, reportDate, reportNumberReason, reportNumberUniqId),
            reason: ""
        };
    }

    const [sessionDate, setSessionDate] = useState(values.date);
    const [sessionNumber, setSessionNumber] = useState(values.number);
    const [sessionReason, setSessionReason] = useState(values.reason);
    
    const [drawnReportShapes, setDrawnReportShapes] = useState(
        values.hasOwnProperty("shapes")
        ?   values.shapes
        :   { left: {}, right: {} }
    );

    const [drawnReportImages, setDrawnReportImages] = useState(
        values.hasOwnProperty("images")
        ?   values.images
        :   { left: null, right: null }
    );
    
    const minDate = new Date(2010, 1, 1);
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 1);

    const template = (values?.template ?? 'default');

    let newReportSessionNumber = null;

    const handleDateChange = (event) => {        
        const dateStr = event.target.value;
        const dateObj = new Date(dateStr);

        let sessionDate;

        if(isValid(dateObj) && dateObj <= maxDate && dateObj >= minDate) {
            sessionDate = dateStr;

            //  if report !== null : date is used in report number format
            if(!isUpdate && reportDate !== null) {
                reportDate = dateObj;
            }
        } else {
            sessionDate = format(today, 'yyyy-MM-dd');

            //  if report !== null : date is used in report number format
            if(!isUpdate && reportDate !== null) {
                reportDate = today;
            }
        }

        setSessionDate(sessionDate);    
        setValue('date', sessionDate);

        if(!isUpdate) {
            newReportSessionNumber = generateSessionNumber(
                                        reportNumberClientName,
                                        reportNumberAnimalName,
                                        reportDate,
                                        reportNumberReason,
                                        reportNumberUniqId
                                    );

            setSessionNumber(newReportSessionNumber);
            setValue('number', newReportSessionNumber);
        }
    };

    let handleReasonChange = null;
    if(!isUpdate && reportNumberReason !== null) {
        handleReasonChange = (event) => {
            reportNumberReason = event.target.value;
            setSessionReason(reportNumberReason);

            newReportSessionNumber = generateSessionNumber(
                reportNumberClientName,
                reportNumberAnimalName,
                reportDate,
                reportNumberReason,
                reportNumberUniqId
            );

            setSessionNumber(newReportSessionNumber);
            setValue('number', newReportSessionNumber);

            return true;
        };
    }


    const handleShapesSaved = (direction, shapes, image) => {
        const newShapes = {...drawnReportShapes };
        newShapes[direction] = shapes;

        const newImages = {...drawnReportImages}
        newImages[direction] = image;
        
        setDrawnReportShapes(newShapes);
        setDrawnReportImages(newImages);
    };

    const handleSaveImageBeforeSubmit = (direction, image) => {
        const data = {...submitStatus.data}
        data.images[direction] = image;
        
        if((!Object.keys(data.shapes.left).length || data.images.left != null)
        && (!Object.keys(data.shapes.right).length || data.images.right != null)) {
            setSubmitStatus({
                processing: false,
                data: data
            });
        } else {
            setSubmitStatus({
                processing: true,
                data: data
            });
        }
    };

    const onSubmitInternal = (data) => {
        data.shapes = drawnReportShapes;
        data.images = drawnReportImages;

        if((Object.keys(drawnReportShapes.left).length && drawnReportImages.left == null)
        || (Object.keys(drawnReportShapes.right).length && drawnReportImages.right == null)) {
            setSubmitStatus({
                processing: true,
                data: data
            });
        } else {
            data.images = drawnReportImages;
            
            onSubmit(data);
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmitInternal)}>
                <Card elevation={3}>
                    <CardContent>
                        <Typography className="detailsCardTitle" color="primary" gutterBottom>
                            Compte rendu
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <MuiTextField 
                                    type="date"
                                    label="Date"
                                    variant="outlined"
                                    onChange={handleDateChange}
                                    value={sessionDate}
                                    required
                                />
                                <input
                                    type="hidden"
                                    {...register("date")}
                                    value={sessionDate}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MuiTextField 
                                    label="Numero"
                                    variant="outlined"
                                    value={sessionNumber}
                                    required
                                    disabled
                                />
                                <input
                                    type="hidden"
                                    {...register("number")}
                                    value={sessionNumber}
                                />
                            </Grid>
                            {sessionsConfig?.practitioners?.active &&
                                <Grid item xs={12}>
                                    <Select
                                        name="practitioner"
                                        label="Praticien·ne"
                                        options={practitionersOptions}
                                        values={values}
                                        defaultValue={""}
                                        errors={errors}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                {reportNumberReason === null
                                    ?   <TextField
                                            name="reason"
                                            label="Motif de la séance"
                                            values={values}
                                            errors={errors}
                                            multiline
                                            rows={2}
                                        />
                                    :   <TextField
                                            name="reason"
                                            label="Motif de la séance"
                                            values={values}
                                            errors={errors}
                                            onChange={reportNumberReason !== null ? handleReasonChange : undefined}
                                            inputProps={{ maxLength: 60 }}
                                            value={sessionReason}
                                        />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="treatment"
                                    label="Résumé du traitement"
                                    values={values}
                                    errors={errors}
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                        {SESSION_SKELETON_IMAGES_INFOS.hasOwnProperty(animal.type) && 
                        <>
                        <Box my={2}>
                            <Divider />
                        </Box>
                        <Grid container spacing={2} mt={3}>
                            <Grid item xs={12} sm={6}>
                                <DrawnReport
                                    animalType={animal.type}
                                    direction="right"
                                    sessionNumber={`${sessionNumber} (D)`}
                                    currentImage={drawnReportImages.right}
                                    template={template}
                                    shapes={drawnReportShapes.right}
                                    isSubmitting={submitStatus.processing && Object.keys(drawnReportShapes.right).length}
                                    onSubmit={handleSaveImageBeforeSubmit}
                                    onShapesSaved={handleShapesSaved}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DrawnReport
                                    animalType={animal.type}
                                    direction="left"
                                    sessionNumber={`${sessionNumber} (G)`}
                                    currentImage={drawnReportImages.left}
                                    template={template}
                                    shapes={drawnReportShapes.left}
                                    isSubmitting={submitStatus.processing && Object.keys(drawnReportShapes.left).length}
                                    onSubmit={handleSaveImageBeforeSubmit}
                                    onShapesSaved={handleShapesSaved}
                                />
                            </Grid>
                        </Grid>
                        </>
                        }
                        <input
                            type="hidden"
                            {...register("shapes")}
                            value={JSON.stringify(drawnReportShapes)}
                        />
                        <input
                            type="hidden"
                            {...register("images")}
                            value={drawnReportImages}
                        />
                    </CardContent>
                    <CardActions>
                        <Button
                            variant="contained"
                            color="cancel"
                            startIcon={<CancelIcon />}
                            component={RouterLink}
                            {...cancelButtonProps}
                        >
                            Annuler
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            startIcon={<SaveIcon />}
                            onClick={handleSubmit}
                        >
                            Enregistrer
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </FormProvider>
    );
}